<template>
<div v-if="load" class="dropdown bounceIn">
    <transition name="slide-fade">
    <div v-show="show" ref="dropdown" :class="{ 'is-touch': isTouchDevice }" @mouseover="triggerShow" @mouseout="triggerClose">
        <slot></slot>
    </div>
    </transition>
</div>
</template>

<script>
export default {
  name: "DropdownNavigation",

  data() {
    return {
      isTouchDevice: false,
      show: false,
      init: false,
      closeTimeout: null,
      dropdownTitle: null,
      dropdownLinks: []
    };
  },

  computed: {
    load() {
      if (this.show) {
        return true;
      }

      if (this.init) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    if (this.show) {
      this.init = true;
    }

    //let list = document.querySelectorAll("#bs4navbar a[data-toggle='dropdown']")
    const list = document.querySelectorAll("#menu-hauptnavigation > li.menu-item-has-children");
    list.forEach((elm) => {
      var linkElms = elm.querySelectorAll(":scope > ul > li > a");
      elm.onmouseover = (e) => {
        e.preventDefault();

        if (window.innerWidth > 992) {
          if (linkElms.length > 0) {
            this.dropdownLinks = [];
            const linkElm = elm.querySelector("a");
            const firstItem = {
              text: linkElm.innerText,
              href: linkElm.getAttribute("href"),
              target: linkElm.getAttribute("target"),
              active: elm.className.indexOf("current-menu-item") != -1
            };

            this.dropdownTitle = firstItem;

            Array.from(linkElms).forEach((a) => {
              let icon = [...a.parentNode.classList].reduce(function(icon, cl) {
                if (cl && cl.indexOf("icon--") === 0) {
                  return cl;
                }

                if (icon && icon.indexOf("icon--") === 0) {
                  return icon;
                }

                return null;
              });

              if (icon) {
                icon = icon.replace("icon--", "fa-") + " far fa-fw";
              }

              const item = {
                icon: icon,
                text: a.innerText,
                href: a.getAttribute("href"),
                target: a.getAttribute("target"),
                active: a.parentNode.className.indexOf("current-menu-item") != -1
              };

              this.dropdownLinks.push(item);
            });

            this.triggerShow();
          } else {
            this.debug("mouseover, close");
            this.close();
          }
        }
      };

      if (linkElms.length > 0) {
        // sonst kann man auf dem Ipad nicht mehr klicken
        elm.onclick = (e) => {
          if (!this.$parent.isDropdownDisabled() && this.$yeti.isTouchDevice && window.innerWidth > 992) {
            e.preventDefault();
            this.isTouchDevice = true;
          }
        };
      }

      elm.onmouseout = (e) => {
        e.preventDefault();
        this.triggerClose();
      };
    });

    this.mouseMoveEvent = (e) => {
      this.onMouseMove(e);
    };

    this.clickEvent = (e) => {
      this.onClick(e);
    };
  },

  methods: {
    debug(m) { // eslint-disable-line
      //console.log(m);
    },

    getDropdownElement() {
      this.dropdownElement = document.querySelector("div.header__dropdown");
      if (!this.dropdownElement) {
        // console.log("header__dropdown not found");
      }

      return this.dropdownElement;
    },

    onMouseMove(e) {
      const elm = this.getDropdownElement();
      const y = elm.offsetTop + elm.offsetHeight;

      if (e.clientY - y > 200) {
        this.debug("mousemove close");
        this.close();
      }
    },

    onClick(e) {
      const elm = this.getDropdownElement();
      const y = elm.offsetTop + elm.offsetHeight;

      if (e.clientY - y > 50) {
        this.debug("click close");
        this.close();
      }
    },

    triggerShow() {
      if (this.$parent.isDropdownDisabled()) {
        return;
      }

      if (this.closeTimeout) {
        this.debug("clear timeout");
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
      }

      if (!this.show) {
        window.addEventListener("mousemove", this.mouseMoveEvent);
        window.addEventListener("click", this.clickEvent);
      }

      this.show = true;
    },

    close() {
      if (this.show) {
        window.removeEventListener("mousemove", this.mouseMoveEvent);
        window.removeEventListener("click", this.clickEvent);
      }

      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
      }

      this.show = false;
    },

    triggerClose() {
      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
        return;
      }

      this.closeTimeout = setTimeout(() => {
        this.debug("closeTimeout()");
        this.close();
      }, 500);
    }
  }
};
</script>
