import Vue from "vue";
import CounterApp from "../components/counter/CounterApp.vue";
import depsloader from "../depsloader";

export default () => {
  const numbers = document.querySelectorAll(".counter .number");
  if (!numbers) {
    return;
  }

  depsloader.load("vue-i18n").then((vueI18n) => {
    Vue.use(vueI18n.VueI18n);

    for(const element of numbers) {
      new Vue({
        el: element,

        components: {
          CounterApp
        },

        computed: {
          numberValue() {
            if (element.dataset.numbervalue) {
              return element.dataset.numbervalue;
            }

            return 0;
          }
        },
        i18n: new vueI18n.VueI18n(vueI18n.options),
        render: h => h(CounterApp)
      });
    }
  });
}
