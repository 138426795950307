// ACHTUNG!! Muss JSON sein, keine Komentare!
export default {
  "entrypoints": {
    "navigation": {"php": false},
    "contact": {"php": false},
    "cookie-notice": {"php": false},
    "swiper": {"php": false},
    "accordion": {"php": false},
    "lightgallery": {"php": false},
    "immo-expose": {"php": false},
    "immoapp": {"php": false},
    "immoapp-savedsearch": {"php": false},
    "immoapp-leadwizard": {"php": false},
    "immoapp-exposecontact": {"php": false},
    "immoapp-search": {"php": false},
    "tabs": {"php": false},
    "modal": {"php": false},
    "map": {"php": false},
    "counter": {"php": false},
    "immo-listing": {"php": false},
    "testimonial-app": {"php": false},
    "testimonial-archive": {"php": false},
    "iframe-resizer": {"php": false},
    "mainnavigation": {"php": true}
  }
}
