import deps from "./depsloader";

const yeti = {
    isTouchDevice: false,
    trackingEnabled: false,
    downlink: navigator.connection && navigator.connection.downlink,

    setupTracking: function() {
      const urlSearchParams = new URLSearchParams(window.location.search.substring(1));

      const campaign = urlSearchParams.get("campaign");
      const keyword = urlSearchParams.get("keyword");

      if (campaign || keyword) {
        deps.load("js-cookie").then((Cookies) => {
          Cookies.set("swtracking", JSON.stringify({
            campaign,
            keyword
          }));
        });
      }
    },

    isSlowInternet: function() {
      return !this.downlink || this.downlink < 3;
    },

    eventCache: [],
    trackEvent: function(category, action, label) {
      if (this.trackingEnabled && window.ga) {
        window.ga("send", "event", category, action, label);

        if (category == "conversion") {
          let event = {
              hitType: "event",
              eventCategory: "conversion_gads_query"
          };

          deps.load("js-cookie").then((Cookies) => {
            const swtracking = Cookies.get("swtracking");
            if (swtracking) {
              const data = JSON.parse(swtracking);

              if ("campaign" in data) {
                event["eventAction"] = data["campaign"];
              }

              if ("keyword" in data) {
                event["eventLabel"] = data["keyword"];
              }

              window.ga("send", event);
            }

            const googleAds = this.googleAds();
            if (googleAds && googleAds.conversionEvent && window.gtag) {
              window.gtag("event", "conversion", {"send_to": googleAds.conversionEvent});
            }
          });
        }
      } else {
        this.eventCache.push({
          category: category,
          action: action,
          label: label
        });
      }
    },

    sendEvents: function() {
      let events = this.eventCache;
      this.eventCache = [];
      events.forEach(event => {
        this.trackEvent(event.category, event.action, event.label);
      })
    },

    enableTracking: function() {
      this.trackingEnabled = true;
      this.sendEvents();
    },

    initTouchNavigation: function() {
      const items = document.querySelectorAll(".navbar .menu-item-has-children");
      const yeti = this;
      var opened = null;
      items.forEach(function(item) {
        if (window.innerWidth < 992) {
          // check for current page anchestor
          if (item.classList.contains("current-menu-parent")) {
            item.classList.add("sw-hovered");
            opened = item;
          }
        }

        item.querySelector("a").onclick = function(event) {
          if (!yeti.isTouchDevice) {
            return true;
          }
          console.log("click");
          if (!item.classList.contains("sw-hovered")) {
            event.preventDefault();
            item.classList.add("sw-hovered");
            if (opened) {
              opened.classList.remove("sw-hovered");
              opened = null;
            }
          } else if (event.clientX / window.innerWidth * 100 > 80) {
            event.preventDefault();
            item.classList.remove("sw-hovered");
          }
        }

        item.onmouseenter = function(event) {
          if (!yeti.isTouchDevice) {
            event.preventDefault();
            item.classList.add("sw-hovered");
          }
        }

        item.onmouseleave = function(event) {
          window.setTimeout(function() {
            event.preventDefault();
            item.classList.remove("sw-hovered");
          }, 100)
        }
      });

      const categoryItems = document.querySelectorAll(".guide-archive__list-category li.has-children > a");
      categoryItems.forEach(function(item) {
        item.onclick = function(event) {
          if (yeti.isTouchDevice) {
            event.preventDefault();
          }
        }
      });
    },

    initTouchCategoryNavigation: function() {
      const items = document.querySelectorAll(".dropdown-navigation > li.has-children");
      const yeti = this;
      items.forEach(function(item) {
        item.querySelector("a").onclick = function(event) {
          if (!yeti.isTouchDevice) {
            return true;
          }

          if (!item.classList.contains("sw-hovered")) {
            event.preventDefault();
            item.classList.add("sw-hovered");
          } else {
            event.preventDefault();
            item.classList.remove("sw-hovered");
          }
        }

        item.onmouseenter = function(event) {
          if (!yeti.isTouchDevice) {
            event.preventDefault();
            item.classList.add("sw-hovered");
          }
        }

        item.onmouseleave = function(event) {
          window.setTimeout(function() {
            event.preventDefault();
            item.classList.remove("sw-hovered");
          }, 100)
        }
      });
    },
}

class VideoResponser {
  constructor(selector) {
      const $video = document.querySelector(selector);
      this.options = {
          selector,
          breakpoints: { default: { src: $video.getAttribute('data-src') } }
      };

      $video.querySelectorAll('[data-src]').forEach(element => this.options.breakpoints[element.getAttribute('data-mw')] = { src: element.getAttribute('data-src') });
      $video.innerHTML = '';

      this.responseVideo(this.options);
      this.resizer();
  }

  resizer() {
      window.addEventListener("resize", () => this.responseVideo(this.options));
  }

  responseVideo(options) {
      const {selector, breakpoints} = options; // get options
      let $video = document.querySelector(selector);
      const widthNow = $video.getAttribute('data-width-now') || null;
      const filteredBreakpoints = Object.keys(breakpoints).filter(key => key <= document.body.clientWidth);
      const maxBreakpoint = filteredBreakpoints.length > 1 ? Math.max(...filteredBreakpoints) : false;
      const nowBreakpoint = maxBreakpoint || 'default'; // choose either the maximum value, if not, then the default

      if(widthNow && widthNow == nowBreakpoint) return; // check if the video needs to be changed

      if (nowBreakpoint) {
        $video.setAttribute('data-width-now', nowBreakpoint);
        $video.src = breakpoints[nowBreakpoint].src;
        $video.playbackRate = 2.0;
        $video.play();
      }
  }
}

if (document.querySelector("#myVideo")) {
  new VideoResponser("#myVideo");
}

const tmp = DOLLAR_YETI_GLOBAL_OVERWRITE; // eslint-disable-line

Object.keys(tmp).forEach(k => {
  yeti[k] = tmp[k];
});

window.addEventListener("touchstart", function onFirstTouch() {
    yeti.isTouchDevice = true;
    window.removeEventListener("touchstart", onFirstTouch, false);
}, false);

yeti.initTouchNavigation();
yeti.initTouchCategoryNavigation();

let body = document.querySelector("body");

function handleNavScroll() {
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if (scrollPosY > 70) {
    body.classList.add("has-scrolled");
  } else {
    body.classList.remove("has-scrolled");
  }
}

window.addEventListener("scroll", () => {
  handleNavScroll();
}, false);

handleNavScroll();

export default yeti;
