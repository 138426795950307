<template>
  <span class="counter__number">
    <animated-number :value="animNumber" round="1" :formatValue="formated"></animated-number>
  </span >
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
  name: "Counter",

  components: {
    AnimatedNumber
  },

  data() {
    return {
      animNumber: 0
    }
  },

  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      threshold: 1
    });
  },

  mounted() {
    this.observer.observe(this.$el);
  },

  methods: {
    formated(value) {
      return this.$n(value, "decimal");
    },

    onElementObserved(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting || entry.intersectionRatio > 1) {
          this.animNumber = this.$root.numberValue;
        }
      }
    }
  }
}
</script>
